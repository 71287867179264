export const VARIABLE_TYPOLOGIES = new Map<string, string>([
    ["1", "waterTemp"],
    ["2", "ph"],
    ["3", "conductivity"],
    ["4", "oxygen"],
    ["5", "turbidimeter"],
    ["6", "ammonium"],
    ["7", "sac"],
    ["8", "nitrate"],
    ["9", "phosphate"],
    ["A", "input"],
    ["B", "salinity"],
    ["C", "totalDissolvedSolids"],
    ["D", "output"],
    ["E", "simCoverage"],
    ["G", "presion"],
    ["H", "humidity"],
    ["N", "level"],
    ["P", "rain"],
    ["Q", "caudal"],
    ["R", "dew"],
    ["S", "surface"],
    ["T", "temperature"],
    ["V", "volume"],
    ["W", "wind"],
    ["X", "dbo"],
    ["Y", "windDirection"],
    ["Z", "cod"]
]);

export const TYPOLOGIES_UNITS = new Map<string, string>([
    ["1", "°C"],
    ["2", "pHu"],
    ["3", " µS/cm"],
    ["4", "ppm"],
    ["5", "U"],
    ["6", "mg/l"],
    ["7", "1/m"],
    ["8", "ppm"],
    ["9", "ppm"],
    ["A", "m³/s"],
    ["B", "PSU"],
    ["C", "mg/l"],
    ["D", "m³/s"],
    ["E", ""],
    ["G", "mbar"],
    ["H", "%"],
    ["N", "m"],
    ["P", "mm"],
    ["Q", "m³/s"],
    ["R", "°C"],
    ["S", "m²"],
    ["T", "°C"],
    ["V", "hm³"],
    ["W", "m/s"],
    ["X", "mg/l"],
    ["Y", "°"],
    ["Z", "mg/l"]
]);

export const EXPORT_PLOT_SVG =
    "path://M83.17139,60.81738a4.00059,4.00059,0,0,1,.00048-5.65722l42-41.98926a4.00207,4.00207,0,0,1,5.65625,0l42,41.98926a4.00026,4.00026,0,0,1-5.65625,5.6582L132,25.65527V128a4,4,0,0,1-8,0V25.65527L88.82812,60.81836A4.00089,4.00089,0,0,1,83.17139,60.81738ZM200,92H176a4,4,0,0,0,0,8h24a4.00427,4.00427,0,0,1,4,4V208a4.00427,4.00427,0,0,1-4,4H56a4.00427,4.00427,0,0,1-4-4V104a4.00427,4.00427,0,0,1,4-4H80a4,4,0,0,0,0-8H56a12.01343,12.01343,0,0,0-12,12V208a12.01343,12.01343,0,0,0,12,12H200a12.01343,12.01343,0,0,0,12-12V104A12.01343,12.01343,0,0,0,200,92Z";
